<template>
  <v-card
    flat
    v-if="servicesId"
    class="px-5 mx-sm-5 mt-10"
  >
    <v-card-title>{{ $t("ExternalServices") }}</v-card-title>
    <v-col>
      <v-card
        outlined
        class="subtitle-1 d-inline-flex align-center justify-space-between px-5 py-3 col-12 col-md-6"
      >
        <div class="d-flex align-center title">
          <v-icon small class="mr-2">
            fas fa-file-invoice
          </v-icon>
          <span>Quickbooks</span>
        </div>
        <div class="d-flex align-center">
          <span class="ml-4 mr-2">{{ $t("Id:") }}</span>
          <span class="px-3 grey lighten-4" style="border-radius: 4px">{{
            servicesId.quickbooksId
          }}</span>
          <v-btn
            :href="`${$appConfig.qboURL}/vendordetail?nameId=${servicesId.quickbooksId}`"
            target="_blank"
            color="orange"
            icon
            class="text-none ml-2"
          >
            <v-icon small class="ma-2">
              fas fa-external-link-alt
            </v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-col>
  </v-card>
</template>

<script>
import {ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSupplierExternalServices.i18n.json');

export default {
  name: 'PageSupplierExternalServices',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      servicesId: null,
      externalServicesErrorMessage: null,
    };
  },
  created() {
    /**
     * GET SUPPLIER
     */
    this.getSupplierExternalServicesId();

  },
  methods: {
    /**
     * GET SUPPLIER EXTERNAL SERVICES ID
     */
    getSupplierExternalServicesId() {
      this.$apiInstance
        .getSupplierExternalServicesId(this.$route.params.supplierUUID)
        .then(
          (supplierExternalServices) => {
            this.servicesId = supplierExternalServices;
          },
          (error) => {
            /**
             * ERROR GET BRAND ADDRESS
             */
            this.externalServicesErrorMessage = ApiErrorParser.parse(error);
          }
        );
    },
  }
};
</script>
